import { useCallback, useEffect, useRef, useState } from 'react'
import request from '@/api/request'

import { sideBarItems } from './caseDetails.const'
import SideBarButton from './components/sideBarButton/sideBarButton'
import {
  DocumentsCard,
  PatientDetailsCard,
} from './components/cards/DetailsCards'
import { ActivityLogs } from './components/activityLogs/ActivityLogs'
import CaseVitalsPage from '../vitals-page/caseVitalsPage'
import { Chat } from '@/components/Chat/Chat'
import { Separator } from '@/components/ui/separator'
import TrackingLink from '@/pages/dashboard/case_details/components/tracking-link/TrackingLink'
import useEvents from '@/api/hooks/useEvents'
import LiveStreamSection from '@/components/custom/liveStreamSection/liveStream.component'
import useUser from '@/api/hooks/useUser'
import { OrderPubNub } from '@/network-hooks/red-push/OrderPubNub'
import useOrders from '@/api/hooks/useOrder'
import { getProperty } from 'dot-prop'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { updateSelectedClientId } from '@/store/redux/user/userSlice'
import { updateSelectedOrderId } from '@/store/redux/orders/orderSlice'
import { useDispatch } from 'react-redux'

const urls = {
  getCaseById: '/ers/v1/clients/redos/cases',
}

const pubnubPusher = new OrderPubNub({
  uuids: ['CHAT', 'ER_ORDER_EVENT', 'ACTIVITY_LOG', 'VITAL_SIGNS'],
})

const CaseDetails = ({ orderId }) => {
  const [caseDetail, setCaseDetail] = useState<any>({})
  const { user } = useUser()
  const dispatch = useDispatch()
  const [patientDetailsVisible, setPatientDetailsVisible] = useState(true)
  const { selectedClientId } = useUser()
  const [activeItems, setActiveItems] = useState<any>([])
  const [caseStatus, setCaseStatus] = useState('')
  const navigate = useNavigate()
  const [, setShowLiveStream] = useState(false)
  const [hasJoinedCall, setHasJoinedCall] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { getEventsForOrder } = useEvents();
  const { ongoingCases } = useOrders();

  const currentCase = ongoingCases.filter((entry: any) => entry.caseId === orderId)?.[0];
  const orderStatus = currentCase?.overallStatus;


  const orderEvents = getEventsForOrder(orderId)?.alerts ?? []

  // const alertAnimationClasses = 'relative animate-borderColorPulse'

  const chatEvents = orderEvents?.filter((entry: any) => {
    return entry?.eventType === 'CHAT'
  })

  const videoEvents = orderEvents?.filter((entry: any) => {
    return entry?.eventType === 'VIDEO'
  })

  let timeoutRef = null
  useEffect(() => {
    return () => {
      pubnubPusher.unsubscribeAll()
      if (timeoutRef) {
        clearTimeout(timeoutRef)
      }
    }
  }, [])



  const fetchCaseDetails = async () => {
    request('GET', `${urls.getCaseById}/${orderId}`)
      .then((data: any) => {
        const sourceId = getProperty(data?.data?.data, 'caseSource.source')
        const caseStatus = getProperty(data?.data?.data, 'overallStatus')

        // http://localhost:5400/dashboard?source=HYDH107B001&orderId=RED_1RGUZDOR It redirect the user to the dashboard if the case is already completed
        // if (caseStatus === 'completed') {
        //   toast.success('Case has been completed')
        //   dispatch(updateSelectedOrderId(''))
        // }
        if (user?.dedicatedToClientIds?.length && !user?.dedicatedToClientIds?.some((entry: any) => entry?.value === sourceId)) {
          navigate('/403')
        }
        setCaseDetail(data?.data?.data)
        setCaseStatus(data?.data?.data?.overallStatus)
        if (data?.data?.data?.type === '5g') {
          setActiveItems('Video Call')
          setHasJoinedCall(true);
        }
        else {
          setActiveItems('Logs')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const onSideBarItemClick = (item: any) => {
    if (item.label === 'Video Call') {
      setHasJoinedCall(item.label === 'Video Call')
    }

    if (item.label === 'Patient Details') {
      setPatientDetailsVisible(!patientDetailsVisible)
    } else {
      setActiveItems([item.label])
    }
  }

  useEffect(() => {
    if (activeItems.includes('Video Call')) {
      setHasJoinedCall(true)
    }
    fetchCaseDetails()
  }, [orderId])

  const handleShowLiveStream = useCallback(
    (e) => {
      setShowLiveStream(e)
    },
    [setShowLiveStream]
  )

  const handleSetHasJoinedCall = useCallback(
    (e) => {
      setHasJoinedCall(e)
    },
    [setHasJoinedCall]
  )

  return (
    // <div className='flex h-full justify-between px-5'>
    <div className='flex h-full justify-between overflow-scroll px-5'>
      <div className='flex flex-col flex-1'>
        {
          !(orderStatus === 'pickedup' && caseDetail?.type === '5g') && (
            <div className={'m-1 flex-1 bg-white text-[#5B6169]'}>
              <TrackingLink
                orderDetails={{ ...caseDetail, overallStatus: orderStatus }}
                linkUrl={caseDetail?.assignmentDetails?.trackingLink ?? ''}
              />
            </div>
          )}
        {orderStatus === 'completed' && (
          <DocumentsCard caseId={orderId} />
        )}

        {(orderStatus === 'pickedup' && caseDetail?.type === '5g') && (
          <div className={'m-1 flex-1 bg-white text-[#5B6169]'}>
            <CaseVitalsPage pusher={pubnubPusher} orderId={orderId} />
          </div>
        )}
      </div>
      <div className={'m-1  flex-1  bg-white pt-1 text-[#5B6169]'}>
        {patientDetailsVisible && <PatientDetailsCard caseData={caseDetail} />}
        {activeItems?.includes('Chat') && (
          <Chat channel_id={orderId} pusher={pubnubPusher} />
        )}
        {activeItems?.includes('Logs') && (
          <ActivityLogs
            pusher={pubnubPusher}
            patientDetailsVisible={patientDetailsVisible}
            casedetail={{ caseId: orderId, sourceId: selectedClientId }}
          />
        )}
        {activeItems?.includes('Video Call') && orderStatus !== 'completed' && (
          <div ref={containerRef}>
            {/* {showLiveStream && ( */}
            <LiveStreamSection
              containerRef={containerRef}
              setHasJoinedCall={handleSetHasJoinedCall}
              hasJoinedCall={hasJoinedCall}
              setShowLiveStream={handleShowLiveStream}
            />
            {/* )} */}
          </div>
        )}
      </div>
      <div className='relative flex h-auto w-[auto] rounded bg-white '>
        <Separator orientation='vertical' className='mx-2 bg-[#D9D9D9]' />
        {orderStatus !== 'completed' ? <div className='pt-4'>
          {sideBarItems?.primary.map((item, index) => {
            return (
              <SideBarButton
                key={item.label + index}
                varient={'primary'}
                active={patientDetailsVisible}
                item={item}
                onClick={onSideBarItemClick}
              />
            )
          })}
          <hr className='m-auto my-2 h-px w-[80%] border border-[#E9EAEB] dark:bg-gray-700' />
          {sideBarItems?.secondary.map((item, index) => {
            return (
              <div
              // className={`${alertAnimationClasses}`}
              >
                <SideBarButton
                  // clasName={alertAnimationClasses}
                  key={item.label + index}
                  varient={'secondary'}
                  active={activeItems?.includes(item?.label)}
                  item={item}
                  onClick={onSideBarItemClick}
                  alertConfig={{
                    Chat: chatEvents,
                    'Video Call': videoEvents,
                  }}
                />
              </div>
            )
          })}
        </div> : <div className='pt-4'>

        </div>}
      </div>
    </div>
  )
}

// const CaseDetails = memo(_CaseDetails);

export default CaseDetails
